import React from 'react';

export default function File({
    input,
    uploadFile,
    confirmInput,
}) {
   
    return (
        <input 
            name={input.nome}
            type="file" 
            placeholder={input.placeholder}
            onBlur={e => input.obrigatorio?confirmInput(e.target):{}} 
            //accept="image/png, image/jpeg, application/pdf"
            onChange={(e)=>uploadFile(input, e.target)}
            required={input.obrigatorio?true:false}
        />
    );
    
   
}       
        
