import axios from "axios";

export async function login(data)  {
    try {
        const config = {
            method: 'post',
            url: 'https://api.neoidea.live/v1/sbd/activation',
            headers: { 
              'Content-Type': 'application/json', 
            },
            data : JSON.stringify(data)
          };

        const response = await axios(config)
        
        return response.data;
    } catch (error) {
        return error.response.data;
    }

}
export async function validation(hash)  {
  const data = {
    "security-key":"917d854d-08ce-49b2-8fcd-75c4968a7751",
    "hash": hash
  }
  try {
      const config = {
          method: 'post',
          url: 'https://api.neoidea.live/v1/sbd-pe/get-activation',
          headers: { 
            'Content-Type': 'application/json', 
          },
          data : JSON.stringify(data)
        };

      const response = await axios(config)
      // console.log(response)
      return response.data;
  } catch (error) {
      return error.response.data;
      // console.log(error)
  }

}