import axios from "axios";

export async function passwordCpf(data)  {
    try {
        const config = {
            method: 'post',
            url: 'https://api.neoidea.live/v1/sbd/recover-password',
            headers: { 
              'Content-Type': 'application/json', 
            },
            data : JSON.stringify(data)
          };

        const response = await axios(config)
        return response.data;
    } catch (error) {
      // console.log(error.response.data.message)

        return error.response.data.message;
    }

}

