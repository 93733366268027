import styled from 'styled-components';
import { isSafari } from 'react-device-detect';

export const Container = styled.div`

  color: ${props => props.layout.colorText?props.layout.text:"#fff"};
    background-color: ${props => props.layout.box && (!props.layout.background || !props.layout.background.includes('.mp4'))?props.layout.box:"unset"};
    background-image: ${props => props.layout.background && !props.layout.background.includes('.mp4')?`url(${props.layout.background})`:"unset"};
    background-size: cover;
    background-attachment: fixed;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 0.8em;
    min-height: 100vh;

    .mensageFinal {
        margin: 30px 0;
    }
    
    .form_background {
        background-color: ${props => props.layout.corBackgroundForm?props.layout.corBackgroundForm:"#fff"};
        padding: 30px;
        width: 100%;
    }
    a:hover {
        color:${props => props.layout.color?props.layout.color:"#fff"};
    }
    form{
        max-width: 750px;
        margin: auto;
    }
    .form-login {
        max-width: 560px !important;
    }
    .form-login > .input_group > .input_fullSize {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 0.5rem;
    }
    button {
        color: ${props => props.layout.colorText?props.layout.colorText:"#fff"};
        background-color: ${props => props.layout.color?props.layout.color:"#fff"};
        &.button_secundary{
            background-color: #8d8d8d};
        }
    /* } */
    button {
      font-family: 'Montserrat', sans-serif;
      height: 2rem;
      padding: 0 1rem;
      border-radius: 5px;
      transition: background-color .2s;
      border: none;
      align-self: flex-end;
      white-space: nowrap;
      cursor: pointer;
      font-size: 1rem;
    }
    .submit_buttons > button {
      font-weight: 400;
      width: 100%;
      margin:10px 0;
    }
    .button_next {
      /* padding: 12px;
      height: unset; */
      /* width: 30%; */
      width: 100%;
      font-weight: 400;
      margin: 3px 0;
    }
    button:hover {
        background-color: ${props => props.layout.color2?props.layout.color2:"#fff"};
    }
    button:active {
        filter:brightness(.8);
    }
    .login_email {
        /* font-size: 1rem; */
    }
    input, select, textarea {
        border: none;
        background: white;
        border-radius: 2px;
        width: 100%;
        border-bottom: 1px solid ${props => props.layout.colorText?props.layout.text2:"#fff"};
    }
    h1.userHeader {
      color: ${props => props.layout.text2?props.layout.text2:"#fff"};
    }
 
    // label:last-child {
    //   div {
    //     width: calc(50% - .5em);
    //   }
    // }
    .phone_input{
        display:grid;
        grid-template-columns: 4em 1fr; 
        gap:1em;
    }
    /* .phonelocal_input{
         display:flex;
         width: calc(50% - .5em);
         /* gap:1em; 
      } */
      
    .btn_isLinked {
      flex-direction: column;
      margin-left: ${() => isSafari?"15px": "0"};
      button {
        width: 100%;
      }
      button:first-child {
        margin-bottom: ${() => isSafari?"15px":"unset"};
      } 
    }
    .submit_buttons{
        margin-top: 1.5rem;
        display: flex;
        /* gap: 1rem; */
        justify-content: center;
        button {
          margin-left: ${() => isSafari?"15px": "0"};
        }
    }
    .button_secundary.reset {
        margin-right: 1rem;
    }
    .popup_container{
        background-color: ${props => props.layout.box?props.layout.box:"#fff"} !important;


    }
    .label_login_email,
    .label_register_email {
        font-size: 1.3rem;
    }

    .popUp__box {
        position: absolute;
        max-width: 800px;
        width: 90%;
        background: #fff;
        padding: 30px;
        box-shadow: 0 0 8px 10px rgb(0 0 0 / 33%);
        flex-direction: column;
        align-items: center;
        z-index: 2;
        max-height: 90%;
    }
    .popUp__texto {
        padding: 10px 30px;
        width: 100%;
        text-align: center;
        height: 100%;
        color: #000;
        line-height: 1.5rem;
    }
    /* .popUp_texto li {
        line-height:
    } */
    .popUp__texto > button {
        margin-top: 30px;
    }
    .checks {
      margin-left: ${() => isSafari?"15px": "0"};
    }
    @media only screen and (max-width: 450px) {
        display:flex;
    }  
    @media only screen and (max-width:520px) {
      .checks {
        margin-left: 0 !important;
      }
      .submit_buttons {
        button {
          margin-left: 0 !important;
        }
      }
    }  
     
`

export const Logo = styled.div`
  display:flex;
  justify-content: center;
  width: 100%;
  min-height: 19em;
  /* max-width: 100px; */
  /* margin: auto; */

    img {
        /* max-height: 120px; */
        max-width: 40vmin;
        max-height: 25vmin;
        padding: 2em;
        position: absolute;
        top: 0px; 
    }

    @media (max-width: 820px) {
        .logo_img {
            max-width: 70vmin;
            max-height: unset;
        }
    }

`


export const InputGroup = styled.div`
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
    gap: 1rem;

    label {
        display: flex;
        flex-direction: column;
        /* gap: .2rem; */
        /* width: calc(50% - .5em); */
        flex: 1 0 35%;
        margin-top: ${() => isSafari?"16px": "unset"};
        margin-left: ${() => isSafari?"15px": "unset"};
    }
    .label_login_email {
      margin-left: 0 !important;
    }
    label > p {
      text-align: left;
      font-size: 1.0rem;
      line-height: normal;
      margin-bottom: 5px;
      font-weight: ${() => isSafari?"300": "400"};
    }
    label > input,
    label > select {
      border: 1px solid #707070;
      border-radius: 5px;
      padding: 10px;
      width: 100%;
      color: #000;
    }
    label > select {
      height: ${() => isSafari?"35px": "unset"};
    }
    input.PhoneInputInput {
      border: 1px solid #707070 !important;
      border-radius: 5px !important;
      padding: 10px !important;
      color: #000 !important;
    }
    label > input:focus-visible,
    label > select:focus-visible,
    input.PhoneInputInput:focus-visible {
      outline: -webkit-focus-ring-color auto 1px;
    }
    input::placeholder {
      font-family: 'Montserrat', sans-serif;
    }
    .midsize:last-child:nth-child(2n) {
        .PhoneInput {
            width: calc(50% - .6em);
        }
    }
    .midsize:last-child:nth-child(2n) input {
      width: calc(50% - .6em);
    }
    .midsize > .PhoneInput {
        div {
            /* width: unset !important; */
        }
    }
    .PhoneInputCountrySelect {
      z-index: 0 !important;
    }
    .PhoneInputCountryIcon.PhoneInputCountryIcon--border {
      border: none !important;
      box-shadow: none !important;
    }
    .PhoneInputCountryIconImg {
      display: block;
      width: 160% !important;
      height: 170% !important;
    }
    .midsize > .PhoneInput .PhoneInputCountrySelectArrow {
        padding: 3px !important;
    }
    .PhoneInputCountrySelectArrow {
      margin-left: 20px;
    }
    .midsize > .PhoneInput .PhoneInputCountry {
        /* max-width: 30.68px; */
    }
    .PhoneInputCountry {
      width: 50px !important;
      padding-bottom: 8px;
    }
    input, select, textarea {
        padding: .24rem;
        &:disabled{
            opacity:.5;
        }
    }
    
    .input_fullSize {
        display: flex;
        gap: 0 1rem;
        width: 100%;
        label {
            width: 100%;
        }
    }
    .options_group{
        display: flex;
        height: 100%;
        align-items: baseline;
        justify-content: flex-start;
        flex-direction: column;
        /* gap: 20px;*/
        margin: .2em 0;
    }
    .options_item{
        display:flex;
        gap: 5px;
        align-items: center;
        input {
            width: 14px !important;
        }
        label{
            white-space:nowrap;
            margin-top: ${() => isSafari?"0px": "unset"};
            font-size: .9rem !important;
        }
    }

    .button_container{
        display: flex;
    }
    .userHeader {
      font-size: 2rem;
      font-weight: ${() => isSafari?"400": "500"};
      text-align: left;
      margin: 12px 0;
      line-height: normal;
      width: 100%;
    }
    .userHeader:first-child {
      margin-bottom: 30px;
    }
    .userHeader:not(:first-child) {
      margin-left:${()=> isSafari?"15px !important":"unset"};
      margin-top:${() => isSafari?"30px !important":"unset"};
    }
    
    @media only screen and (max-width: 520px) {
        flex-direction: column !important;
        label{
          width: 100%;
          margin-left: unset !important;
        }
        label > p {
          font-size: .8rem;
        }
        button {
          width:auto;
        }
        .userHeader {
          font-size: 1.8rem;
        }
        .userHeader:not(:first-child) {
          margin-left: unset !important;
        }
        .midsize:last-child:nth-child(2n) {
          .PhoneInput {
              width: unset !important;
          }
        }
        .midsize:last-child:nth-child(2n) input {
          width: unset !important;
        }
    }
    .warning,
    input.PhoneInputInput.warning {
        border: 2px solid crimson !important;
        //background-color: lightpink !important;
        //position:relative;
    }
    /* .option.warning::after{
        content: "*";
        width: 80%;
        position: absolute;
        height: 80%;
        color: transparent;
        border-radius: 50%;
        border: 1px solid crimson !important;
        background-color: #ffb6c157 !important;
    } */
    .PhoneInput .PhoneInputCountrySelectArrow {
        border-bottom-style: solid !important;
        border-top-style: solid !important;
        border-left-style: solid !important;
        border-right-style: solid !important;
        border-bottom-width: 1px !important;
        border-top-width: 0px !important;
        border-left-width: 0px !important;
        border-right-width: 1px !important;
        height: 4px !important;
        text-size-adjust: unset !important;
    }
    

`
  export const PolicyContainer = styled.div`
      background-color: #fff;

      .policy-container {
        max-width: 80vw;
        margin: auto;
        padding: 5rem 0;
        text-align: justify;
        line-height: 21px;
      }
      .policy-container ol, .policy-container ul {
        margin-left: 60px;
      }
      .policy-container h3 {
          margin-top: 20px;
      }
      .policy-container h1 {
        line-height: 35px;
        text-align: left;
    }
  
  `