import axios from "axios";

export async function login(data)  {
    try {
        const config = {
            method: 'post',
            url: 'https://api.neoidea.live/v1/sbd/activation',
            headers: { 
              'Content-Type': 'application/json', 
            },
            data : JSON.stringify(data)
          };

        const response = await axios(config)
        
        return response.data;
    } catch (error) {
        return error.response.data;
    }

}

