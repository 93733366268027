import React from 'react';
import LoadingIcon from '../../assets/loading'
import { Container} from './styles'

export default function Loading({loadingStatus}) {
 
    return (
      loadingStatus?
      <Container>
        <LoadingIcon/>
      </Container>
      :<></>
    )
}



