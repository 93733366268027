import React, {useState } from 'react';

export default function Birthday({
    input,
    text,
    AddDataField,
    confirmInput,
    registerData,
}) {

    const [birthdayToShow, setBirthdayToShow] = useState("");

    function dateMask(value) {
        return value
          .replace(/\D/g, "") 
          .replace(/(\d{2})(\d)/, "$1/$2") 
          .replace(/(\d{2})(\d)/, "$1/$2")
          .replace(/(\/\d{4})\d+?$/, "$1"); 
    }
  
    function confirmBirthdayDate (target) {
        const today = Date.now()
        const {value} = target
        const day = registerData.lang==="en"?value.slice(3, 5):value.slice(0, 2);
        const month = registerData.lang==="en"?value.slice(0, 2):value.slice(3, 5);
        const year = value.slice(-4);

        const time = new Date(month+"/"+day+"/"+year);
        
        if(!isNaN(time)&&(((today-time)/31104000000)>=0)){
            AddDataField(input, year+"-"+month+"-"+day)
            confirmInput(target)
        }else {
            setBirthdayToShow("");
            target.value = ""
            confirmInput(target)
        }
    }

    return (
        <input 
            name={input.nome} 
            type="text"
            placeholder={text.dataFormat}
            onChange={e => setBirthdayToShow(dateMask(e.target.value))} 
            onBlur={e => input.obrigatorio?confirmBirthdayDate(e.target):{}}
            value={birthdayToShow}
            required={input.obrigatorio?true:false}
        />
    );
    
   
}       
        
