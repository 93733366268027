import React, { useState } from "react";
import * as utils from "../utils";
import { login } from "./services";

import "./style.css";

export default function PasswordRecovery() {
  const [cpfMask, setCpfMask] = useState("");
  const [urlRedirect, seturlRedirect] = useState("");

  const [urlBase, setUrlBase] = useState("");
  const [neo, setNeo] = useState("");
  const [user, setUser] = useState("");
  const [pass, setPass] = useState("");

  const handleSend = async (e) => {
    const inputCpf = document.querySelector("#input_cpf");
    const inputPassword = document.querySelector("#input_password");
    const button = document.querySelector("#button_send");

    e.preventDefault();
    const cpf = inputCpf.value.trim();
    const password = inputPassword.value;

    if (utils.isCpf(cpf) && utils.IsSomething(password)) {
      button.setAttribute("disabled", "true");
      const auth = {
        cpf: cpf,
        password: password,
      };
      const data = await login(auth);

      if (data.url) {
        // caso resposta com url
        return success(data);
        // return console.log(data)
      }
      // caso erro
      noSuccess(data.message);
      button.removeAttribute("disabled");
    } else if (!utils.isCpf(cpf)) {
      noCpf();
    } else {
      noPass();
    }
  };
  const success = (data) => {
    const { url, email } = data;
    seturlRedirect(url);

    const form = document.querySelector("#retrive_password_form");
    const message = document.querySelector("#final__message");

    form.remove();
    message.innerHTML = `<p>Seu cadastro foi ativado com sucesso! </>
        <p>Atenção, o seu e-mail autorizado para acessar a Plataforma é: ${email}</p>
        `;

    const link = document.querySelector("#link_redirect");
    link.classList.remove("hideDiv");
  };
  const redirectFunc = async () => {
    await setUrlBase(urlRedirect.split("?")[0]);
    const params = urlRedirect.split("?")[1].split("&");
    await setNeo(params[0].split("=")[1]);
    await setUser(params[1].split("=")[1]);
    await setPass(params[2].split("=")[1]);

    const formPost = document.querySelector("#testForm");

    formPost.submit();
  };
  const noCpf = () => {
    clearForm();
    const popUp = document.querySelector("#input__defalt_popup");
    const popUpText = document.querySelector("#input__defalt_popup_text");

    popUpText.innerHTML = "<p>Adicione um CPF válido.</p>";
    popUp.classList.remove("hideDiv");
  };
  const noPass = () => {
    clearForm();
    const popUp = document.querySelector("#input__defalt_popup");
    const popUpText = document.querySelector("#input__defalt_popup_text");

    popUpText.innerHTML = "<p>O campo senha deve ser preenchido.</p>";
    popUp.classList.remove("hideDiv");
  };
  const noSuccess = (data) => {
    clearForm();

    const popUp = document.querySelector("#input__defalt_popup");
    const popUpText = document.querySelector("#input__defalt_popup_text");

    popUpText.innerHTML = `<p>${data}</p>`;
    popUp.classList.remove("hideDiv");
  };
  const closePopUp = () => {
    const popUp = document.querySelector("#input__defalt_popup");
    popUp.classList.add("hideDiv");
    showForm();
  };
  const clearForm = () => {
    const inputCpf = document.querySelector("#input_cpf");
    const inputPassword = document.querySelector("#input_password");
    const button = document.querySelector("#button_send");
    inputCpf.classList.add("hideDiv");
    inputPassword.classList.add("hideDiv");
    button.classList.add("hideDiv");
  };
  const showForm = () => {
    const inputCpf = document.querySelector("#input_cpf");
    const inputPassword = document.querySelector("#input_password");
    const button = document.querySelector("#button_send");
    inputCpf.classList.remove("hideDiv");
    inputPassword.classList.remove("hideDiv");
    button.classList.remove("hideDiv");
  };

  return (
    <div className="container__default">
      <form
        onSubmit={handleSend}
        id="retrive_password_form"
        className="input__defalt"
      >
        <div className="input__defalt_popup hideDiv" id="input__defalt_popup">
          <p id="input__defalt_popup_text"></p>
          <button
            onClick={closePopUp}
            className="input__defalt input__defalt_popup_button"
            type="button"
          >
            ok
          </button>
        </div>
        <input
          className="input__defalt"
          id="input_cpf"
          value={cpfMask}
          onChange={(e) => setCpfMask(utils.cpfMask(e.target.value))}
          placeholder="CPF"
        />
        <input
          className="input__defalt"
          type="password"
          id="input_password"
          placeholder="Senha"
        />
        <button className="input__defalt" id="button_send" type="submit">
          Enviar
        </button>
      </form>
      <div className="final__message">
        <div id="final__message"></div>
        <a id="link_redirect" onClick={redirectFunc} className="hideDiv">
          CLIQUE PARA ENTRAR NA PLATAFORMA DO CONGRESSO
        </a>
      </div>
      <form id="testForm" method="post" action={urlBase} target="_top">
        <input type="hidden" name="neo" value={neo} />
        <input type="hidden" name="user" value={user} />
        <input type="hidden" name="password" value={pass} />
      </form>
    </div>
  );
}
