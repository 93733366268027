import React from 'react';

export default function Country({
    input,
    AddDataField,
    confirmInput,
    countryData,
}) {


    return (
        <select 
            name={input.nome} 
            placeholder={input.placeholder}
            onChange={e => AddDataField(input, e.target.value)} 
            onBlur={e => input.obrigatorio?confirmInput(e.target):{}}
            required={input.obrigatorio?true:false}
        >
            <option value="">{input.nome}</option>
            {countryData?.map(option=>{
                return <option key={option.codigo} value={option.codigo}>{option.valor}</option>
            })}
        </select>
    );
    
   
}       
