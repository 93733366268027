import styled from 'styled-components';

export const Container = styled.div`

    display:flex;
    justify-content: center;
    align-items:center;
    background-color: rgba(0,0,0,.5);
    position:fixed;
    width:100%;
    height:100%;
    top: 0;
    padding:1em;
    .popup_container {
        background-color: #fff;
        display:flex;
        flex-direction:column;
        align-items:center;
        gap:2em;
        border-radius: 8px;
        padding:2em;
        box-shadow: 10px 10px 30px rgba(0,0,0,.3);
        max-width: 700px;
        button{
            align-self: center;
        }
    }
    ul{
        list-style: none;
        align-self: flex-start;
        font-size: 1.2em;
    }
`