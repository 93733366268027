import React, { useEffect, useState } from 'react';
import { getTerms, getLayout } from '../register/api';
import { PolicyContainer } from '../register/styles';


export default function PrivacyPolicy({match}) 
{
  const [policy, setPolicy] = useState('')
  const [layout, setLayout] = useState('')

  const getAll = async () => {
    const getLayoutData = await getLayout(match.params.neoId)
    const getPolicyText = await getTerms(getLayoutData.dados.mdiId)

    setLayout(getLayoutData.dados)
    setPolicy(getPolicyText.content);
  }

  useEffect(() => {
    getAll()
    
  }, [])
  
  return (
    <PolicyContainer layout={layout}>
      <div className="policy-container" dangerouslySetInnerHTML={{__html:policy}}/>
    </PolicyContainer> 
    )
}