import axios from "axios";
const baseRul =
  "https://app.neoidea.com.br/sistema/index.php?r=outlet/services";

export function enable(element) {
  element.removeAttribute("disabled");
}
export function desable(element) {
  element.setAttribute("disabled", "disabled");
}
export function hide(element) {
  element.classList.add("disable");
}
export function show(element) {
  element.classList.remove("disable");
}
export function getAllInputs() {
  const inputs = document.querySelectorAll(["input","select"]);

  var arr = [];
  for (var i = inputs.length; i--; arr.unshift(inputs[i]));
  return arr;
}
export function getAllInputsSelects() {
  const inputs = document.querySelectorAll("input");
  const inputsSelect = document.querySelectorAll("select");

  var arrinput = [];
  for (let i = inputs.length; i--; arrinput.unshift(inputs[i]));

  var arrSelect = [];
  for (let i = inputsSelect.length; i--; arrSelect.unshift(inputsSelect[i]));
  return [...arrSelect, ...arrinput];
}
export function serachWarning(arr) {
  return (
    arr.filter((element) => element.classList.contains("warning")).length === 0
  );
}
export function applyWarning(arr) {
  const uiGender = document.querySelector(".gender__input");
  if (uiGender) unsetWarning(uiGender);

  arr.forEach((input) => {
    unsetWarning(input);
    if (input.value === "") {
      setWarning(input);
    }
  });
  if (
    arr.find((input) => input.type === "radio" && input.checked) === undefined
  ) {
    if (uiGender) setWarning(uiGender);

    arr
      .filter((input) => input.type === "radio")
      .forEach((element) => {
        setWarning(element);
      });
  }
}
export function IsEmail(email) {
  var emailCheck = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
  return emailCheck.test(email);
  //return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
}
export function isCpf(cpf) {
  return cpf.length === 14 || !isNaN(cpf);
}
export function IsSomething(value) {
  return value !== "";
}
export async function apiPOSTformData(url, formData) {
  const req = await fetch(url, {
    body: formData,
    method: "POST",
    mode: "cors",
  });

  return await req.json();
}
export async function apiPOSTData(url, data) {
  const config = {
    method: "post",
    url: baseRul + url,
    params: data,
  };

  const response = await axios(config);
  return response.data;
}
export function setWarning(element) {
  element.classList.add("warning");
}
export function unsetWarning(element) {
  element.classList.remove("warning");
}
export function cpfMask(value) {
  return value
    .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{3})(\d)/, "$1.$2") // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1"); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}
export function crmMask(value) {
  return value
    .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
   
}
export function dateMask(value) {
  return value
    .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{2})(\d)/, "$1/$2") // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{2})(\d)/, "$1/$2")
    .replace(/(\/\d{4})\d+?$/, "$1"); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}
export function zipCodeMask(value) {
  return value
    .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{2})(\d)/, "$1.$2") // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, "$1-$2")
    .replace(/(-\d{3})\d+?$/, "$1"); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}
export function phoneMask(value) {
  return value
    .replace(/\D/g, "") 
}
export function phoneDDIMask(value) {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{0})(\d)/, "$1+$2") 
}
export function phoneLocalMask(value) {
  return value
  .replace(/\D/g,"")   //Remove tudo o que não é dígito                
  .replace(/^(\d{2})(\d)/g,"($1) $2") //Coloca parênteses em volta dos dois primeiros dígitos
  .replace(/(\d)(\d{4})$/,"$1-$2")    //Coloca hífen entre o quarto e o quinto dígitos
}
