import React from 'react';
import Logo from '../../assets/logoIcon';
import { Container } from './styles'

export default function Header({text, neoId, isLoginRegister, finishRegister, layout}) {
  
  const agent = window.navigator.userAgent.toLowerCase();

  function showLogin() {  
    const electron  = /electron/.test(agent);
    const app  = /neomobile/.test(agent);

    /* if(!electron && !app && neoId!=="neo-idea-platform" && !isLoginRegister && !finishRegister && layout.mdiId!=="229"){
        return <p>{text.jaRegistrado} <a href={`https://app.neoidea.com.br/${neoId}`}> {text.clickAqui}</a></p>
    } */
  }
  //console.log(layout);
    return (
      <Container>
          <div className="header_title">
            {/* <h1>{layout.mdiId!=="229"?text.cadastro:text.cadastroJoyce}</h1> */}
            {showLogin()}
          </div>
          {!layout.show_logo?<></>:<Logo width={80} color1={layout.color} color2={layout.text} colorlogo={layout.colorlogo} colorlogo2={layout.colorlogo2} />}
          {/* {layout.mdiId==="229" || !layout.show_logo?<></>:<Logo width={80} color1={layout.color} color2={layout.text} />} */}
      </Container>
    )
}



