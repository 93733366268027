import React , { useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import {en, pt} from './text';
import { Container, Logo, InputGroup} from './styles'
import { serachWarning, setWarning, unsetWarning } from '../utils';
import { getRegister, getLayout, getCountry, getProfession, getLoginRegister, getForgotPassword } from "./api"
import { Link } from "react-router-dom";
import md5 from 'md5'
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { isValidPhoneNumber } from 'react-phone-number-input/mobile';

import Header from './components/Header';
import Loading from './components/Loading';
import SimpleForm from "./components/SimpleForm";
import ComplexForm from "./components/ComplexForm";
import PopUp from "./components/Popup";
import CustomFields from "./components/CustomFields";

export default function Register({match}) {

  const [formtype, setFormtype] = useState(0);
  const [layout, setLayout] = useState();
  const [countryData, setCountryData] = useState([]);
  const [professionData, setProfessionData] = useState([]);
  const [text, setText] = useState(pt);
  const [invite, setInvite] = useState(false);
  const [registerData, setRegisterData] = useState({});
  const [registerCustomData,setRegisterCustomData] = useState({});
  const [contador, setContador] = useState(5);
  const [loading, setLoading] = useState(false);
  const [popup, setPopup] = useState(false);
  const [popupList, setPopupList] = useState(false);
  const [invalidUrl, setInvalidUrl] = useState(false);
  const [customFields, setCustomFields] = useState(false);
  const [isCheckedPolicy, setIsCheckedPolicy] = useState(false);
  const [isCheckedNewsletter, setIsCheckedNewsletter] = useState(false);

  const [validStatus, setValidStatus] = useState(false)
  const [loginStatus, setLoginStatus] = useState(false)
  const [registerStatus, setRegisterStatus] = useState(false)
  const [isLoginRegister, setIsLoginRegister] = useState(false)
  const [isLinked, setIsLinked] = useState(false)
  const [finishRegister, setFinishRegister] = useState(false)

  const [modalIsOpen, setIsOpen] = useState(false);

  const neoId = match.params.neoId;
  const grupoId = match.params.grupoId;

  const termIframe = <iframe style={{marginTop: '50px', height: 'calc(100vh - 270px)'}} src={`/${neoId}/politicadeprivacidade`} width={"100%"} sandbox={"allow-modals allow-forms allow-popups allow-scripts allow-same-origin"}></iframe>;

  useEffect(() => {

    const getAll = async () => {
      const [
        getRenderData,
        getCountryData,
        getProfessionData,
      ] = await Promise.all([getLayout(neoId), getCountry(neoId), getProfession(neoId)]);

      if (getRenderData.retorno) {
        setLayout(getRenderData.dados);
        setInvite(getRenderData.dados.convidado);
        setFormtype(getRenderData.dados.tipo_form_cadastro);

        setRegisterData(registerData=>{return {...registerData, lang:getRenderData.dados.idioma}});
        setRegisterData(registerData=>{return {...registerData, inviteCode:match.params.codigo}});
        setRegisterData(registerData=>{return {...registerData, mdi_id:getRenderData.dados.mdiId}});
        setRegisterData(registerData=>{return {...registerData, slug:neoId}});
        

        if (getRenderData.dados.campos_dinamicos) hardCodeCustonFields(getRenderData.dados.mdiId, getRenderData.dados.campos_dinamicos)
        //if (getRenderData.dados.campos_dinamicos||getRenderData.dados.mdiId==="229") hardCodeCustonFields(getRenderData.dados.mdiId, getRenderData.dados.campos_dinamicos)

        if (getRenderData.dados.idioma !== "ptbr") setText(en);
        if (getCountryData.retorno) setCountryData(getCountryData.dados.paises);
        if (getProfessionData.retorno) setProfessionData(getProfessionData.dados.profissoes);

      //   if (getRenderData.dados.mdiId === "229") {
      //     return (
      //       import('./joyce.scss')
      //       .then(() => {
      //         console.log("Joyce layout loaded");
      //       })

      //     )
      // }
      }else{
        setInvalidUrl(text.slugError)
      }
      
    };
    getAll();
  }, [match,neoId,text]);
  async function handleLogin ( e ){
    e.preventDefault()
    if(confirmAllInputs()){

        try{
            setPopupWarning("loading", true)
            const loginRegister = await getLoginRegister(registerData,registerCustomData,grupoId)
            setPopupWarning("loading", false)
            
            setIsLoginRegister(loginRegister)

        }catch(e){
            setPopupWarning(e.message)
        }

    }
    
  }
  async function handleForgotPassword ( e ){
      e.preventDefault()


      try{
          setPopupWarning("loading", true)
          const forgotPassword = await getForgotPassword(registerData)
          setPopupWarning("loading", false)
          
          if(forgotPassword) setPopupWarning(text.emailSenha)

      }catch(e){
          setPopupWarning(e.message)
      }

      
  }
  async function handleRegister (e ){
    e.preventDefault()
       
    if(confirmAllInputs()){
      
      try{
        setPopupWarning("loading", true)
        const register = await getRegister(registerData,registerCustomData, grupoId)
        if(register===true){
          setFinishRegister(true)
          setPopupWarning("loading", false)
        }else {

          throw new Error(text.erroInesperado)
        }

      }catch(e){
          setPopupWarning(e.message)
        }
    }
  }
  const handleOnChangeNewsletter = (e) => {
    const newletterStatus = e.target.checked
    setIsCheckedNewsletter(newletterStatus)
    setRegisterData(registerData => {
      return {...registerData, newsletter: newletterStatus}
  })}

  const handleOnChangePolicy = (e) => {
    setIsCheckedPolicy(e.target.checked)
  }
  // const handleOpenPolicy = () => {
  //   setPopupWarning(text.termsText)
  // }


  const showForm = () => {

          return (<>
            <SimpleForm
            neoId={neoId}
            invite={invite}
            text={text}
            resetForm={resetForm}
            data={{registerData,setRegisterData}}
            valid={{validStatus,setValidStatus}}
            login={{loginStatus,setLoginStatus}}
            register={{registerStatus,setRegisterStatus}}
            setIsLoginRegister={setIsLoginRegister}
            setIsLinked={setIsLinked}
            confirmInput={confirmInput}
            setPopupWarning={setPopupWarning}
            confirmAllInputs={confirmAllInputs}
            customFields={customFields}
            grupoId={grupoId}
            layout={layout}
            />
            {formtype===1 ? <ComplexForm
            neoId={neoId}
            invite={invite}
            text={text}
            countryData={countryData}
            professionData={professionData}
            setPopupWarning={setPopupWarning}
            data={{registerData,setRegisterData}}
            confirmInput={confirmInput}
            confirmInputPhone={confirmInputPhone}
            confirmInputMobile={confirmInputMobile}
            register={{registerStatus,setRegisterStatus}}
            grupoId={grupoId}
            layout={layout}
            />
            :<></>}
            
            {customFields?
            <>
            {!loginStatus ? (formtype===1 ? (validStatus?<h1 className="userHeader">3.{text.userAdd}</h1> : '') : (validStatus?<h1 className="userHeader">2.{text.userAdd}</h1> : '')) : '' }
            <CustomFields 
              customFields={customFields}
              customData={{registerCustomData,setRegisterCustomData}}
              confirmInput={confirmInput}
              confirmInputPhone={confirmInputPhone}
              validStatus={validStatus}
              countryData={countryData}
              professionData={professionData}
              setPopupWarning={setPopupWarning}
              confirmOption={confirmOption}
              registerData={registerData}
              text={text}
              layout={layout}
            /></>:<></>}
            
            </>
            )
  
      
  }
  const showLogo = () => {
    if(layout.logo){
      return <Logo><img className="logo_img" src={layout.logo} alt={`Logo ${layout.nome}`} /></Logo>
    }
  }
  const showSubmitBtn = () => {

    function disabledDoubleClikSubmit() {
      const buttonSubmit = document.querySelector("button[type='submit']")
      buttonSubmit.setAttribute('disabled','disabled')
      window.setTimeout(()=> {
        buttonSubmit.removeAttribute('disabled')
      },2000)
    }

    if(validStatus&&!loginStatus){
      return(
        <div className="submit_buttons">
            {/* <button onClick={resetForm} className="button_secundary reset" type="reset">{text.limpar}</button> */}
            <button type="submit" onClick={disabledDoubleClikSubmit()}>{text.enviar}</button>
        </div>
    )}
  }
  const loginSuccess = () => {
    setInterval(() => {
        const count =setContador(contador=> {
            if(contador===1) {
              loginRedirect()
              return count
            }
            return contador-1
        })

    }, 1000);
  }
  const loginRedirect = async () => {
      const formPost = document.querySelector('#testForm');
      formPost.submit()
  }
  const finalMessage = () => {

    if(finishRegister) return <h3 className="successtext_register">{text.sucessoNoCadastro}<p>{text.javalidouEntrar}</p><p><button className="button__default--action btn-login-plataforma" type="button" onClick={()=>window.location.href = layout.domain && layout.domain != '' ? layout.domain : `https://app.neoidea.com.br/${neoId}`}>{text.vaParaPlataforma}</button></p></h3>

    else if(!layout.autenticacao2Fatores&&isLoginRegister) {
    return (
        <>
          <p>{text.redirecionado+contador+text.segundos}</p>
          <form id="testForm" method="GET" action={"https://app.neoidea.com.br/neoidea/index.php"} target="_top">
              <input type="hidden" name="neo" value={registerData.mdi_id}/>
              <input type="hidden" name="user" value={md5(registerData.email)}/>
              <input type="hidden" name="password" value={md5(registerData.password)}/>
          </form>
        </>
    )}else if(isLinked) return (
      <>
        <h3 className="set__titulo">{text.parabens}</h3>
        <h3 className="mensageFinal">{text.possuiVinculo}</h3>
        <div className="submit_buttons">
            { /*<button className="button__default--action" type="button" onClick={resetForm}>{text.outroEmail}</button>*/ }
            <button className="button__default--action" type="button" onClick={()=>window.location.href = layout.domain && layout.domain != '' ? layout.domain : `https://app.neoidea.com.br/${neoId}`}>{text.vaParaPlataforma}</button>
        </div>
      </>
    )
  }
  const showLogin = () => {
    function disabledDoubleClikSubmit() {
      const buttonSubmit = document.querySelector("button[type='submit']")
      buttonSubmit.setAttribute('disabled','disabled')
      window.setTimeout(()=> {
        buttonSubmit.removeAttribute('disabled')
      },2000)
    }
    if(loginStatus){return(
        <>
            <div className="input_fullSize">
                <label  htmlFor="login_password"><p>{text.senha} <span>*</span> </p>
                    <input 
                        name={text.senha} 
                        type="password" 
                        onChange={e => setRegisterData({...registerData, password:e.target.value})} 
                        onBlur={e => confirmInput(e.target)} 
                        value={registerData.password}
                        required
                        />

                  </label>
              </div>
              <div className="checks">
                        <div className="newsletter">
                            <input
                              type="checkbox"
                              id="newsletter"
                              name="newsletter"
                              value
                              checked={isCheckedNewsletter}
                              onChange={handleOnChangeNewsletter}
                            /><p>{text.newsletter}</p>
                        </div>
                        
                        <div className="policy">
                            <input
                              type="checkbox"
                              id="policy"
                              name="policy"
                              checked={isCheckedPolicy}
                              onChange={handleOnChangePolicy}
                            /><p>{text.policy} <Link onClick={openModal}>{text.policyName}</Link></p>
                        </div>
                      </div>
                  <div className="btn_isLinked">
                    {/* <button onClick={handleLogin}> */}
                    <button type="submit" onClick={disabledDoubleClikSubmit()}>
                        {text.login2}
                    </button>
                    <button className="button_notButton" onClick={e=>handleForgotPassword(e)}>{text.esqueceuSenha}</button>
                  </div>      
          </>
      )}
  }


  function resetForm() {
    setRegisterData({
      lang: layout.idioma,
      slug:  match.params.neoId,
      mdi_id:layout.mdiId,
      inviteCode:match.params.codigo?match.params.codigo:"",
      email:"",
    })
    setRegisterCustomData({})

    setContador(5)
    setValidStatus(false)
    setLoginStatus(false)
    setRegisterStatus(false)
    setIsLoginRegister(false)
    setIsLinked(false)
    setFinishRegister(false)
  }
  function setPopupWarning (type, append){
    setPopupList(false)
    setLoading(false)

    if(type==="loading") setLoading(append)
    else if(append) {
      setPopupList(append)
      setPopup(type)
    }
    else setPopup(type)
  }
  function confirmInput (input, confirmValue){
    if(!confirmValue&&input.value===""){
         return setWarning(input)
        
    }else if(confirmValue&&input.value!==confirmValue) {
         return setWarning(input)
    }
    unsetWarning(input)
  }
  function confirmInputPhone(input, value){
    if(input.value===''){
      return setWarning(input)
      
    }else if(input.value&&input.value!==""){
      const isPossible = isPossiblePhoneNumber(value.toString())

      if(!isPossible){
        return setWarning(input)
      }
    }
    unsetWarning(input)
  }
  function confirmInputMobile(input, value){
    if(input.value===''){
      return setWarning(input)
      
    }else if(input.value&&input.value!==""){
      const isMobile = isValidPhoneNumber(value.toString())

      if(!isMobile){
        return setWarning(input)
      }
    }
    unsetWarning(input)
  }

  function confirmOption(element) {
    const optionsGroup = Array.from(document.querySelectorAll(`[name="${element.name}"]`))
    const checkedOption =  optionsGroup.filter(item=>item.checked)

     if(checkedOption.length) return unsetWarning(element)
     else return setWarning(element)
   
  }
  function confirmAllInputs (){
    const inputs = [...Array.from(document.querySelectorAll("input")),...Array.from(document.querySelectorAll("select")),...Array.from(document.querySelectorAll("textarea")),...Array.from(document.querySelectorAll("radio"))]
    inputs.forEach(input=>{
      input.focus() 
      input.blur()
    })
    const warningInputs = inputs.filter(input => input.classList.contains("warning"))
    if(warningInputs.length){
      const warningNamesList = warningInputs.map(input=>input.name)
      const differentWarnings = warningNamesList.filter((actual, i) => warningNamesList.indexOf(actual) === i);
      setTimeout(() => setPopupWarning(text.confiraCamposEmVermelho, differentWarnings), 500);
      
      return false
    }else if(!isCheckedPolicy) {
      setPopupWarning(text.policyError)
      return false
    } else return true
  }

  function hardCodeCustonFields (mdi_id, dataCustomFields) {
    if (mdi_id==="292") setCustomFields(
      [
        // ...dataCustomFields,
        {
          nome: "Profissão",
          obrigatorio: true,
          placeholder: "Profissão",
          slug: "profissao",
          tipo: "profissao",
        },
        {
          nome: "CRM",
          obrigatorio: true,
          condicao:["profissao","medico"],
          placeholder: "CRM",
          slug: "crm",
          tipo: "idnumerico",
        },
        {
          nome: "CRO",
          obrigatorio: true,
          condicao:["profissao","dentista"],
          placeholder: "CRO",
          slug: "cro",
          tipo: "idnumerico",
        },
        {
          nome: "Outra Profissão",
          obrigatorio: true,
          condicao:["profissao","outros"],
          placeholder: "Informe a profissão",
          slug: "outra-profissao",
          tipo: "text",
        },
        /*{
          nome: "Código",
          obrigatorio: false,
          placeholder: "Código",
          slug: "codigo",
          tipo: "text",
        }*/
    ])
    else if (mdi_id==="286") setCustomFields(
      [
        // ...dataCustomFields,
        {
          nome: "Profissão",
          obrigatorio: true,
          placeholder: "Profissão",
          slug: "profissao",
          tipo: "profissao",
        },{
          nome: "CRM",
          obrigatorio: true,
          condicao:["profissao","medico"],
          placeholder: "CRM",
          slug: "crm",
          tipo: "idnumerico",
        },{
          nome: "Carteira de estudante",
          obrigatorio: true,
          condicao:["profissao","academico"],
          placeholder: "Carteira de Estudante",
          slug: "carteira-profissional-estudante",
          tipo: "binary",
        },{
          nome: "Carteira profissional",
          obrigatorio: true,
          condicao:["profissao","profissional-saude"],
          placeholder: "Carteira de Estudante",
          slug: "carteira-profissional-estudante",
          tipo: "binary",
        }
    ])
    // else if (mdi_id==="229") 
    // setCustomFields(
    //   [
    //     // ...dataCustomFields,
    //     {
    //       nome: "Nascimento",
    //       obrigatorio: true,
    //       placeholder: "Nascimento",
    //       slug: "nascimento",
    //       tipo: "nascimento",
    //     },{
    //       nome: "Sexo",
    //       obrigatorio: true,
    //       opcoes: [
    //         {codigo: "Feminino", valor: "Feminino"},
    //         {codigo: "Masculino", valor: "Masculino"},
    //         {codigo: "Outro", valor: "Outro"},
    //       ],
    //       placeholder: "Sexo",
    //       slug: "sexo",
    //       tipo: "radio",
    //     },
    //     {
    //       nome: "País",
    //       obrigatorio: true,
    //       placeholder: "País",
    //       // placeholder: "Carteira de Estudante",
    //       slug: "pais",
    //       tipo: "pais",
    //     },{
    //       nome: "Celular",
    //       obrigatorio: true,
    //       placeholder: "Celular",
    //       slug: "celular",
    //       tipo: "telefone",
    //     }
    // ])
    else setCustomFields(dataCustomFields)
  }

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    if(isLoginRegister) loginSuccess()
  }, [isLoginRegister])

    return (
      layout?(
        <Container layout={layout}>
          {layout.background && layout.background.includes('.mp4') ? 
            <video id="background-video" autoPlay loop muted>
              <source src={layout.background} type="video/mp4" />
            </video> : <></>
          }
          {showLogo()}
          <div className="form_background">
          {/* <div className="form_background joyceLayout"> */}

              <form noValidate={!validStatus&&!loginStatus?true:false} className={!validStatus?'form-login':''} onSubmit={!loginStatus? e=>!validStatus?e.preventDefault():handleRegister(e):e=>handleLogin(e)}>
                
                <Header text={text} neoId={neoId} isLoginRegister={isLoginRegister} finishRegister={finishRegister} layout={layout}/>
                  {isLoginRegister||isLinked||finishRegister?finalMessage():
                    <>
                      <InputGroup className="input_group">
                        {showForm()}
                        {showLogin()}
                      </InputGroup>
                      {/* {layout.mdiId === "229"&&validStatus?<p className="footer_joyce">{text.camposObrigatorios}</p>:<></>} */}
                      {validStatus&&!loginStatus &&
                      <div className="checks">
                        <div className="newsletter">
                            <input
                              type="checkbox"
                              id="newsletter"
                              name="newsletter"
                              value
                              checked={isCheckedNewsletter}
                              onChange={handleOnChangeNewsletter}
                            /><p>{text.newsletter}</p>
                        </div>
                        
                        <div className="policy">
                            <input
                              type="checkbox"
                              id="policy"
                              name="policy"
                              checked={isCheckedPolicy}
                              onChange={handleOnChangePolicy}
                            /><p>{text.policy} <Link onClick={openModal}>{text.policyName}</Link></p>
                        </div>
                      </div> }
                      {showSubmitBtn()}
                      <Modal isOpen={modalIsOpen}>
                          <div style={{'textAlign': 'center'}}><button className="button__default--action btn-login-plataforma" type="button" onClick={closeModal}>{text.fechar}</button></div>
                          {termIframe}
                      </Modal>
                    </>
                  }
              </form>
          </div>
          {finishRegister?<Loading loadingStatus={loading} />:<></>}
        <PopUp setPopup={setPopup} popupStatus={popup} popupList={popupList}/>
        
      </Container>
      ):<><PopUp setPopup={false} popupStatus={invalidUrl} /></>

    )

}



