import axios from "axios";

const base = "https://app.neoidea.com.br/sistema/index.php?r=outlet/services/";

const api = axios.create({
  baseURL: base,
});
export async function getLayout(slug, email) {
  const response = await api.post("getLayoutExterno", null, {
    params: {
      slug, email
    },
  });
  return response.data;
}
export async function getCountry(slug) {
  const response = await api.post("https://app.neoidea.com.br/sistema/index.php?r=outlet/services/getPaisesExterno", null, {
    params: {
      slug,
    },
  });
  return response.data;
}
export async function getProfession(slug) {
  const response = await api.post("https://app.neoidea.com.br/sistema/index.php?r=outlet/services/getProfissoesExterno", null, {
    params: {
      slug,
    },
  });
  return response.data;
}
export async function getTerms(mdi_id) {
  const response = await api.get(`https://app.neoidea.com.br/sistema/index.php?r=outlet/services/getTermoUso&mdi_id=${mdi_id}`);
  return response.data;
}
export async function getGrupos({email,slug}) {
  const response = await api.get(`https://app.neoidea.com.br/sistema/index.php?r=outlet/services/getUserGroups&slug=${slug}&email=${email}`);
  return response.data ?? [];
}
export async function getEmail({ email,slug,convite, lang }) {
  const response = await api.post("checkEmailExterno", null, {
    params: {
        slug,
        email,
        convite,
        lang
    },
  });
  if(response.data.erro){
      throw new Error(response.data.descricao)
  }else{
    return response.data.dados.codigo_status;
  }
}
export async function getRegister(
  {
  email,
  slug,
  inviteCode,
  password,
  name,
  surname,
  birthday,
  profession,
  sex,
  country,
  zipCode,
  city,
  state,
  adress,
  number,
  region,
  observations,
  cellphoneDDI,
  cellphone,
  phoneDDI,
  phone,
  phonelocal,
  lang,
  mdi_id,
  newsletter
},registerCustomData, grupoId) {
  
  const allData = {
    slug,
    email,
    convite:inviteCode?inviteCode:"",
    senha:password?password:"",
    nome: name? name:"",
    sobrenome:surname?surname:"",
    nascimento: birthday? birthday:"",
    profissao: profession? profession:"",
    sexo: sex? sex:"",
    pais:country?country:"",
    cep:zipCode?zipCode:"",
    cidade:city?city:"",
    estado:state?state:"",
    endereco:adress?adress:"",
    numero:number?number:"",
    bairro:region?region:"",
    complemento:observations?observations:"",
    celular_ddi:cellphoneDDI?cellphoneDDI:"",
    celular_numero:cellphone?cellphone:"",
    telefone_ddi:phoneDDI?phoneDDI:"",
    telefone_numero:phone?phone:"",
    telefone_local:phonelocal?phonelocal:"",
    lang,
    mdi_id:mdi_id?mdi_id:"",
    newsletter,
    grupoId,
    ...registerCustomData
  }
  
  const body = mountFormData(allData)

  const response = await axios({
    method: "post",
    baseURL:base,
    url:"cadastrarUsuarioExterno",
    data: body,
    headers: { "Content-Type": "multipart/form-data" },
  });
   
  if(response.data.erro){
      throw new Error(response.data.descricao)
  }else{
    return response.data.retorno;
  }
}
export async function getForgotPassword({ email, lang }) {
  
  const response = await api.post("recuperarSenhaFaExterno", null, {
    params: {
        email,
        lang,
    },
  });
  if(!response.data.retorno){
      throw new Error(response.data.descricao)
  }else{
    return response.data.retorno;
  }
}
export async function getLoginRegister({ email, lang, slug, inviteCode, password, mdi_id, newsletter }, registerCustomData, grupoId) {

  const allData = {
    slug,
    email,
    convite:inviteCode?inviteCode:"",
    senha:password,
    lang,
    mdi_id:mdi_id,
    newsletter,
    grupoId,
    ...registerCustomData
  }
  
  const body = mountFormData(allData)
  const response = await axios({
    method: "post",
    baseURL:base,
    url:"vincularCadastroExterno",
    data: body,
    headers: { "Content-Type": "multipart/form-data" },
  });
  
  if(!response.data.retorno){
      throw new Error(response.data.descricao)
  }else{
    return response.data.retorno;
  }
}
export async function getAdress({ zipCode, country, lang }) {
  const response = await api.post("getCepExterno", null, {
    params: {
      pais:country,
      codigo_postal:zipCode,
      
    },
  });
  return response.data.dados;
}

function mountFormData(data){
  let bodyFormData = new FormData();

  const allDataKeys = Object.keys(data)

  allDataKeys.forEach(key => {
    if(Array.isArray(data[key])) {
      for (var i = 0; i < data[key].length; i++) {
        bodyFormData.append(`${key}[]`, data[key][i]);}
    }
    else bodyFormData.append(key, data[key])
  })

  return bodyFormData
}