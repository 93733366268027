import React from 'react';
import { getEmail,getGrupos,getForgotPassword, getLoginRegister } from '../../api';
import { IsEmail } from '../../../utils';
import { match } from 'assert';


export default function SimpleForm({
    data,
    neoId,
    invite,
    text,
    valid,
    login,
    register,
    setIsLinked,
    setIsLoginRegister,
    resetForm,
    confirmInput,
    setPopupWarning,
    confirmAllInputs,
    grupoId,
    layout
}) {

    const {registerData,setRegisterData} = data
    const {registerStatus,setRegisterStatus} = register
    const {loginStatus, setLoginStatus} = login
    const {validStatus,setValidStatus} = valid


    async function handleVerifyEmail ( e ){

        try{
            
            if(IsEmail(registerData.email)){
                const data = {
                    email:registerData.email,
                    slug:neoId,
                    convite: registerData.inviteValue,
                }
                setPopupWarning("loading", true)
                const verifyResponse = await getEmail(data, registerData.lang)
                setPopupWarning("loading", false)

                if(verifyResponse===0){
                    setRegisterStatus(true)
                    setValidStatus(true)
                }else if(verifyResponse===1){
                    setIsLinked(true)
                }else if(verifyResponse===2){

                    const grupos = await getGrupos(data)
                    setRegisterData({...registerData, grupos})
                    

                    setPopupWarning(text.jaPossuiEmailCadastrado)
                    setLoginStatus(true)
                    setValidStatus(true)
                }else{
                    throw new Error(text.erro)
                }
            }else {
                throw new Error(text.emailInvalido)
            }
        }catch(e){
            setPopupWarning(e.message)
        }
        
    }


    const showIviteCodeOrValidate = () => {
        if(invite){return(
                <label htmlFor="invite_code">{text.codigo} *
                    <div className="button_container">
                        <input 
                            name="invite_code" 
                            type="text" 
                            disabled={validStatus}
                            onChange={e => setRegisterData({...registerData, inviteValue:e.target.value})} 
                            onBlur={e => confirmInput(e.target)} 
                            value={registerData.inviteValue}
                        />
                        {showButtonVerify(handleVerifyEmail)}
                    </div>
                </label>
        )}else{
            return (
                showButtonVerify(handleVerifyEmail)
            )
        }
    }
 
    const showRegister = () => {
        if(registerStatus){return(
            <>
                <label className="input_fullSize midsize" htmlFor="confirm_email"><p>{text.confimeEmail} <span>*</span></p> 
                        <input
                            name={text.confimeEmail} 
                            placeholder={text.confimeEmail}
                            type="text"
                            onBlur={e => confirmInput(e.target, registerData.email)}
                            required
                            />
                </label>
                <label className="midsize" htmlFor="password"><p>{text.senha} <span>*</span></p> 
                    <input 
                        name={text.senha} 
                        placeholder={text.senha}
                        type="password" 
                        onChange={e => setRegisterData({...registerData, password:e.target.value})}
                        onBlur={e => confirmInput(e.target)} 
                        value={registerData.password}
                        required
                        />
                </label>
                <label className="midsize" htmlFor="confirmar senha"><p>{text.confimeSenha} <span>*</span></p> 
                    <input 
                        name={text.confimeSenha} 
                        placeholder={text.confimeSenha}
                        type="password" 
                        onBlur={e => confirmInput(e.target, registerData.password)} 
                        required
                        />
                </label>
                <label className="midsize" htmlFor="name"><p>{text.nome} <span>*</span></p> 
                    <input 
                        name={text.nome} 
                        placeholder={text.nome}
                        type="text" 
                        onChange={e => setRegisterData({...registerData, name:e.target.value})} 
                        onBlur={e => confirmInput(e.target)} 
                        value={registerData.name}
                        required
                        />
                </label>
                <label className="midsize" htmlFor="surname"><p>{text.sobrenome} <span>*</span></p> 
                    <input 
                        name={text.sobrenome}
                        placeholder={text.sobrenome}
                        type="text" 
                        onChange={e => setRegisterData({...registerData, surname:e.target.value})}
                        onBlur={e => confirmInput(e.target)} 
                        value={registerData.surname}
                        required
                        />
                </label>
            </>
        )}
    }
    const showButtonVerify = (buttonFunction) => {
        if (!validStatus) 
            return <button onClick={buttonFunction} type="submit" className="button_next">{text.proximo}</button>
    }


    return (
        <>  
          <div className="input_fullSize">
              <label htmlFor="email" className={validStatus?"label_register_email":"label_login_email"}>{!loginStatus?(validStatus?<h1 className="userHeader">{text.userDados}</h1>:<p>{text.digiteSeuEmail}</p>):<h1 className="userHeader">{text.login3}</h1>}
                  <input 
                      name="email" 
                      type="text" 
                      className={validStatus?"input__default":"input__default login_email"}
                      required
                      placeholder={text.email}
                      disabled={validStatus}
                      onChange={e => setRegisterData({...registerData, email:e.target.value.trim()})} 
                      value={registerData.email}
                      />
              </label>
          {showIviteCodeOrValidate()}
          </div> 
            {showRegister()}

        </>               
    );
    
   
}       
        
