import React, {useState } from 'react';
import 'react-phone-number-input/style.css'
import PhoneInput, { formatPhoneNumber, parsePhoneNumber } from 'react-phone-number-input'
import en from 'react-phone-number-input/locale/en.json'
import ptbr from 'react-phone-number-input/locale/pt-BR.json'

export default function PhoneNumber({
    input,
    AddDataField,
    confirmInputPhone,
    confirmInput,
    registerCustomData,
    registerData,
    defaultCountry
}) {
    function phoneMask(value) {
        return value
            .replace(/\D/g, "") 
    }
    function phoneDDIMask(value) {
        return value
            .replace(/\D/g, "")
            .replace(/(\d{0})(\d)/, "$1+$2") 
    }

    // https://www.npmjs.com/package/react-phone-number-input       
    
    const [value, setValue] = useState()
    return (
            <PhoneInput
                labels={defaultCountry == 'BR' ? ptbr : en}
                defaultCountry={defaultCountry}
                international={false}
                name={input.nome} 
                placeholder={input.placeholder}
                value={value}
                onChange={(value) => {
                    let parsedPhone = null
                    if (value !== undefined&&value.toString()!='') {
                        parsedPhone = parsePhoneNumber(value.toString());
                    }
                    if (parsedPhone != null) {
                        value = `+${parsedPhone.countryCallingCode} ${formatPhoneNumber(value)}`
                    }
                    setValue(value)
                    AddDataField(input, value)
                }}
                onBlur={e => input.obrigatorio?confirmInputPhone(e.target,value):{}}
                required={input.obrigatorio?true:false}
            />
    )
    
   
}       
        
