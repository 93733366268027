import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// import NewRegister from "./pages/newRegister";
import Register from "./pages/register";
import PasswordRecovery from "./pages/passwordRecovery";
import PasswordRecoveryCpf from "./pages/passwordRecoverycpf";
import PasswordRecoveryCpfnoRedirect from "./pages/passwordRecoverycpfnoRedirect";
import LoginSBOT from "./pages/loginSBOT";
import LoginSBD from "./pages/loginSBD";
import LoginSBDValidation from "./pages/loginSBDValidation";
import LoginSBDPE from "./pages/loginSBDPE";
import LoginISCAR from "./pages/loginISCAR";
import PrivacyPolicy from './pages/privacyPolicy';

export default function Routes() {

    return ( 
        <BrowserRouter>
            <Switch >
                <Route
                    exact path="/iscar"
                    component={() => {
                      global.window && (global.window.location.href = 'https://iscar2020ufrn.com.br/');
                      return null;
                    }}
                />
                <Route path = { `${__dirname}loginISCAR:hash?` }
                exact component = { LoginISCAR }
                /> 
                <Route path = { `${__dirname}loginSBDPE:hash?` }
                exact component = { LoginSBDPE }
                /> 
                <Route path = { `${__dirname}loginSBOT` }
                exact component = { LoginSBOT }
                /> 
                <Route path = { `${__dirname}loginSBD` }
                exact component = { LoginSBD }
                /> 
                <Route path = { `${__dirname}loginSBDauth` }
                exact component = { LoginSBDValidation }
                /> 
                <Route path = { `${__dirname}password` }
                exact component = { PasswordRecovery }
                /> 
                <Route path = { `${__dirname}SBDpassword` }
                exact component = { PasswordRecoveryCpf }
                /> 
                <Route path = { `${__dirname}SBDpasswordNotRedirect` }
                exact component = { PasswordRecoveryCpfnoRedirect }
                /> 
                <Route path = { `${__dirname}newRegister/:neoId` }
                component = { Register }
                />
                <Route path = { `${__dirname}:neoId/politicadeprivacidade`} exact component = { PrivacyPolicy }/> 
                
                <Route path = { `${__dirname}:neoId?/:codigo?/:grupoId?`} component = { Register }/> 

            </Switch>  
        </BrowserRouter>
    );
}