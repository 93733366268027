import React from 'react';

export default function Radio({
    input,
    AddDataField,
    confirmOption
}) {


    return (
        <div className="options_group">
        {input.opcoes.map(item=>{
            return <div key={item.codigo} className="options_item">
                <input 
                type="radio" 
                id={item.valor} 
                name={input.nome} 
                value={item.codigo}
                className="option"
                onChange={e => AddDataField(input, e.target.value)} 
                onBlur={e => input.obrigatorio?confirmOption(e.target):{}} 
                />
                <label htmlFor={item.valor}>{item.valor}</label>
            </div>
        })}
    </div>
    );
    
   
}       
        
