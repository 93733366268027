import React from 'react';
import { Container } from './styles'

export default function Popup({setPopup, popupStatus, popupList}) {
 
    return (
      popupStatus?
      <Container>
        <div className="popUp__box">
          <div id="popUp" className="popUp__texto">
            <h3>{popupStatus}</h3>
            {popupList?
              <ul>
                {popupList?popupList?.map(item=>{
                  return <li>{item}</li>
                }):<></>}
                
              </ul>
              :<></>}
            <button onClick={()=>setPopup?setPopup(false):console.log("erro")}>ok</button>
          </div>
        </div>
      </Container>
      :<></>
    )
}



