export const pt = {
  parabens: "Parabéns!",
  digiteSeuEmail: "Digite seu e-mail",
  criarconta: "Criar Conta",
  campos: "*Campos obrigatórios",
  sucessoLogin: `Cadastro feito com Sucesso! Cadastro Realizado.`,
  possuiVinculo: `Você já está inscrito nessa plataforma.`,
  falhaLogin: `Falha na autenticação, por favor verifique seus dados.`,
  emailInvalido: `Adicione um email válido`,
  cadastro: "CADASTRO",
  validaeEmail: "Necessário para login e validar cadastro",
  verificar: "Registrar-se",
  limparEmail: "Limpar e-mail",
  senha: "Senha",
  confimeSenha: "Confirme sua Senha",
  confimeEmail: "Confirme seu Email",
  uploadExcedeLimite: "O arquivo excede o tamanho limite de 4 mega bytes.",
  tamanhoArquivo: "Tamanho máximo 4 mega bytes.",
  nome: "Nome",
  sobrenome: "Sobrenome",
  nascimeento: "Data de nascimento",
  profissao: "Profissão",
  genero: "Gênero",
  feminino: "Feminino",
  masculino: "Masculino",
  outro: "Outro",
  cep: "CEP",
  descubraCep: "Clique aqui e descubra seu CEP",
  pais: "País",
  estado: "Estado",
  cidade: "Cidade",
  endereco: "Endereço",
  bairro: "Bairro",
  numero: "Número",
  complemento: "Complemento",
  celular: "Celular",
  telefone: "Telefone",
  enviar: "Enviar",
  limpar: "Limpar",
  fazendoLogin: "Fazendo Login...",
  jaPossuiEmailCadastrado:'Parabéns, você já tem cadastro por ter participado de algum outro evento na Plataforma Neo. Clique em ok para efetuar o login.',
  jaPossuiEmailCadastradoSBC:"Usuário já cadastrado, complete seu cadastro e efetue o login para finalizar sua inscrição.",
  verificandoEmail: "Verificando email...",
  confiraCamposEmVermelho: "Confira os campo(s): ",
  erroSenha: "A senha não confere.",
  erroemail: "O email não confere.",
  paisValido: "Adicione um País válido",
  profissaoValido: "Adicione uma Profissão válida",
  email: "E-mail",
  sucessoNoCadastro: "Uma mensagem para confirmação do cadastro foi enviada para o seu e-mail. Siga as instruções da mensagem para validar seu cadastro.",
  javalidouEntrar: "Já validou seu e-mail? Clique no botão abaixo.",
  falhaNoCadastro: "Atenção, falha no cadastro: ",
  erroInesperado: "Erro inesperado no cadastro.",
  dataFormat: "DD/MM/AAAA",
  aguarde: "Aguarde",
  jaPossuiEmailvinculo: "Usuário já possui vinculo com o Neo solicitado.",
  codigo: "Código do Convite",
  validar: "Validate",
  erro: "Ocorreu um erro, por favor recarregue a página e tente novamente",
  errocodigo: "Código inválido",
  errocodigodigito: "O Código deve ter 6 digitos",
  jaRegistrado: "Já tem cadastro?",
  clickAqui: "CLIQUE AQUI PARA FAZER LOGIN.",
  disabledEmail:"Preencha o campo email e clique em VERIFICAR para liberar o formulário.",
  disabledCRM:"Preencha o campo Profissão para liberar o formulário.",
  disabledEnd: "Preencha o campo país para liberar o formulário.",
  disabledCep: "Preencha o campo CEP para liberar o formulário.",
  carteiraProfissional: "Carteira profissional ",
  carteiraEstudante: "Carteira de estudante",
  crm: "CRM",
  sucessoLoginSBC:`Inscrição realizada com Sucesso! Cadastro Realizado.`,
  outroEmail:`Voltar e usar outro email`,
  slugError:`Slug não identificado, solicite novo link`,
  login:`Fazer login`,
  login2:`Entrar`,
  login3:`Login`,
  vaParaPlataforma: "Fazer login na plataforma",
  esqueceuSenha:`Esqueceu a senha?`,
  emailSenha: 'Um mensagem com a recuperação de senha foi enviado para seu e-mail.',
  redirecionado: 'Você será direcionado em ',
  segundos: ' segundo(s)',
  cadastroJoyce: "Nova Conta Utilizador Joyce",
  camposObrigatorios: "* Campos obrigatórios",
  newsletter: "Eu concordo em receber Newsletter. (Opcional)",
  policy: `Li e concordo com a `,
  policyName: 'Política de Privacidade e Termos de Uso',
  policyError: 'É necessário aceitar a nossa Política de Privacidade e Termos de Uso para realizar o cadastro.',
  proximo: 'Próximo',
  userDados: "1. Dados Pessoais",
  userEnd: "2. Endereço",
  userAdd: " Informações Adicionais",
  changePhoto: "Mudar Foto",
  select: "Selecione",
  fechar: "Fechar",
};

export const en = {
  parabens: "Congratulations!",
  digiteSeuEmail: "Type your e-mail",
  criarconta: "Register",
  campos: "*Required fields",
  sucessoLogin: `Sucsses, your order is done.`,
  possuiVinculo: `You already have a register with this email.`,
  falhaLogin: `Something is wrong with yout informations. Please check and send again.`,
  emailInvalido: `Not a valid E-mail`,
  cadastro: "REGISTER",
  validaeEmail: "Needed for to valid your registration",
  verificar: "Register",
  limparEmail: "Clean Email",
  senha: "Password",
  confimeSenha: "Confirm Password",
  confimeEmail: "Confirm Email",
  uploadExcedeLimite: "The file excede the maximun size (4 mb).",
  tamanhoArquivo: "Maximun file size 4 mb.",
  nome: "Name",
  sobrenome: "Surname",
  nascimeento: "Date of Birth",
  profissao: "Profession",
  genero: "Gender",
  feminino: "female",
  masculino: "male",
  outro: "other",
  cep: "ZIP Code",
  descubraCep: "",
  pais: "Country",
  estado: "State",
  cidade: "City",
  endereco: "Adress line 1",
  bairro: "Adress line 2",
  numero: "Number",
  complemento: "Observations",
  celular: "Mobile number",
  telefone: "Phone number",
  enviar: "Send",
  limpar: "Clear",
  fazendoLogin: "Login, please wait",
  jaPossuiEmailCadastrado: "You already have a registration with us, login for to complete the order.",
  jaPossuiEmailCadastradoSBC:"You already have a registration with us, complete your data and login to finish your inscription.",
  verificandoEmail: "Validating email...",
  confiraCamposEmVermelho: "Please check these fields: ",
  erroSenha: "The password is not the same, please check.",
  erroemail: "The email is not the same, please check.",
  paisValido: "Put a valid country",
  profissaoValido: "Put a valid Profession",
  email: "E-mail",
  sucessoNoCadastro: "An confirmation message was sent to your e-mail. Follow the instructions to validate your register.",
  javalidouEntrar: "Have you validated your email? Click on button below.",
  falhaNoCadastro: "Error, please check:",
  erroInesperado: "Unespected error. Contact us for to know more.",
  dataFormat: "MM/DD/YYYY",
  aguarde: "Wait",
  jaPossuiEmailvinculo: "You already have the subscription on this Neo.",
  codigo: "Invitation Code",
  validar: "Validate",
  erro: "An erro occur, please refresh and try again",
  jaRegistrado: "Already have a register?",
  errocodigo: "Invalid invitation code",
  errocodigodigito: "Invitation code must have 6 digits",
  clickAqui: "CLICK HERE TO SING IN.",
  disabledEmail:"Fill the email field and click in validate to enable this form.",
  disabledCRM:"Fill the Profession field to enable this form.",
  disabledEnd: "Fill the county field to enable this form.",
  disabledCep: "Fill the ZIP code field to enable this form.",
  carteiraProfissional: "Professional license",
  carteiraEstudante: "Studant Id",
  crm: "CRM",
  sucessoLoginSBC:`Sucsses, your inscription is done.`,
  outroEmail:`Go back and use another e-mail`,
  slugError:`unexpected slug, contact suport`,
  login:`Sign in`,
  login2:`Join`,
  login3:`Login`,
  vaParaPlataforma: "Sign in here",
  esqueceuSenha:`Forgot your password?`,
  emailSenha: 'A message with your password recovery was sent to your e-mail.',
  redirecionado: 'Your will be redirected in ',
  segundos: ' second(s)',
  cadastroJoyce: "New User Joyce",
  camposObrigatorios: "* Required fields",
  newsletter: "I agree to receive Newsletter (Optional)",
  policy: `I have read and agree with the `,
  policyName: 'Privacy Policy and Terms of Use.',
  policyError: 'It is necessary to accept our Privacy Policy and Terms of Use to finish your registration.', 
  proximo: 'Next',
  userDados: "1. Personal Data",
  userEnd: "2. Address",
  userAdd: " Additional Information",
  changePhoto: "Change Photo",
  select: "Select",
  fechar: "Close"
};
