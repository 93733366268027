import React, { useEffect, useState } from 'react';
import Date from '../Inputs/Date';
import Text from '../Inputs/Text';
import File from '../Inputs/File';
import TextArea from '../Inputs/TextArea';
import Select from '../Inputs/Select';
import Checkbox from '../Inputs/Checkbox';
import Radio from '../Inputs/Radio';
import PhoneNumber from '../Inputs/PhoneNumber';
import Birthday from '../Inputs/Birthday';
import Profession from '../Inputs/Profession';
import Country from '../Inputs/Country';
import NumericId from '../Inputs/NumericId';
import PhoneNumberLocal from '../Inputs/PhoneNumberLocal';

export default function CustomFields({
    validStatus,
    customData,
    customFields,
    professionData,
    countryData,
    confirmInput,
    confirmInputPhone,
    confirmOption,
    setPopupWarning,
    registerData,
    text,
    layout
}) {
    const {registerCustomData,setRegisterCustomData} = customData
    const [inputs, setInputs] = useState(showInputs(registerCustomData, customFields));

    function AddDataField(input, value, type) {
        const field = type?input.slug+type:input.slug;
        let newData = registerCustomData;
        newData[field] = value
        setRegisterCustomData(newData);
        setInputs(showInputs(newData,customFields))

    }
    function showInputs(newData, customFields) {

        const data = customFields?customFields.filter(input=>{
            const conditionField = input.condicao?input.condicao[0]:""
            const condition = input.condicao?input.condicao[1]:""

            return (input.condicao&&newData[conditionField]===condition)||!input.condicao

        }):""
        return data
    }

    function AddDataCheckbox(input, target) {
        if(registerCustomData[input.slug]){
            if(target.checked){
                const newData = [...registerCustomData[input.slug], target.value]
                AddDataField(input,newData)
            }else {
                const newData = registerCustomData[input.slug].filter(option=>{
                    return option!==target.value
                })
                AddDataField(input,newData)
            }
        }else{
            AddDataField(input,[target.value])
        }
    }

    function uploadFile(input, element) {
       //    4080000 ~ 3.9mb
       /*if(element.files[0].size>4080000){
            element.value = ""
            setPopupWarning(text.uploadExcedeLimite)
       }else */
       
       AddDataField(input, element.files[0])
     
    }
    return (
            
          validStatus?inputs?.map((input, index)=>{
                return parseInt(input.grp_id) > 0 && registerData.grupos && registerData.grupos.length > 0 && registerData.grupos.includes(input.grp_id) ? <></> : (
           
                   input.tipo==="date"?
                          <label className="midsize" key={input.slug+index} ><p>{input.nome} <span>{input.obrigatorio?"*":""}</span> </p>
                              <Date  
                                  input={input}
                                  AddDataField={AddDataField}
                                  confirmInput={confirmInput}
                                  registerCustomData={registerCustomData}
                                  />
                          </label>
                      :input.tipo==="text"?

                          <label className="midsize" key={input.slug+index} ><p>{input.nome} <span>{input.obrigatorio?"*":""}</span> </p>
                                  <Text  
                                      input={input}
                                      AddDataField={AddDataField}
                                      confirmInput={confirmInput}
                                      registerCustomData={registerCustomData}
                                      />
                          </label>
                      :input.tipo==="binary"?
              
                          <label className="midsize" key={input.slug+index} ><p>{input.nome} <span>{input.obrigatorio?"*":""}</span> </p>
                              <File  
                                  input={input}
                                  uploadFile={uploadFile}
                                  confirmInput={confirmInput}
                                  />
                          </label>
                      :input.tipo==="longtext"?
                          <label className="input_fullSize midsize" key={input.slug+index}><p>{input.nome} <span>{input.obrigatorio?"*":""}</span> </p>
                              <TextArea  
                                  input={input}
                                  AddDataField={AddDataField}
                                  confirmInput={confirmInput}
                                  registerCustomData={registerCustomData}
                                  />
                          </label>
                      :input.tipo==="dropdown"?
              
                          <label className="midsize" key={input.slug+index} ><p>{input.nome} <span>{input.obrigatorio?"*":""}</span> </p>
                              <Select  
                                  input={input}
                                  AddDataField={AddDataField}
                                  confirmInput={confirmInput}
                                  />
                          </label>
                      :input.tipo==="check"?
              
                          <label className="midsize" key={input.slug+index}><p>{input.nome} <span>{input.obrigatorio?"*":""}</span> </p>
                              <Checkbox  
                                  input={input}
                                  AddDataCheckbox={AddDataCheckbox}
                                  confirmOption={confirmOption}
                                  />
                          </label>
                      :input.tipo==="radio"?
              
                          <label className="midsize" key={input.slug+index}><p>{input.nome} <span>{input.obrigatorio?"*":""}</span> </p>
                              <Radio  
                                  input={input}
                                  AddDataField={AddDataField}
                                  confirmOption={confirmOption}
                                  />
                          </label>    
                      :input.tipo==="phone"?
              
                          <label className="midsize" key={input.slug+index} ><p>{input.nome} <span>{input.obrigatorio?"*":""}</span> </p>
                                  <PhoneNumber
                                      input={input}
                                      AddDataField={AddDataField}
                                      confirmInput={confirmInput}
                                      confirmInputPhone={confirmInputPhone}
                                      registerCustomData={registerCustomData}
                                      registerData={registerData}
                                      defaultCountry={layout.idioma == 'ptbr' ? 'BR' : 'US'}
                                      />
                              </label>
                      :input.tipo==="phonelocal"?
              
                          <label className="midsize" key={input.slug+index} ><p>{input.nome} <span>{input.obrigatorio?"*":""}</span> </p>
                              <PhoneNumberLocal
                                  input={input}
                                  AddDataField={AddDataField}
                                  confirmInput={confirmInput}
                                  registerCustomData={registerCustomData}
                                  registerData={registerData}
                                  />
                          </label>
                      :input.tipo==="nascimento"?
              
                          <label className="midsize" key={input.slug+index} ><p>{input.nome} <span>{input.obrigatorio?"*":""}</span> </p>
                              <Birthday
                                  input={input}
                                  AddDataField={AddDataField}
                                  confirmInput={confirmInput}
                                  registerData={registerData}
                                  text={text}
                                  />
                          </label>
                      :input.tipo==="profissao"?
              
                          <label className="midsize" key={input.slug+index} ><p>{input.nome} <span>{input.obrigatorio?"*":""}</span> </p>
                              <Profession  
                                  input={input}
                                  AddDataField={AddDataField}
                                  confirmInput={confirmInput}
                                  professionData={professionData}
                                  />
                          </label>
                      :input.tipo==="pais"?
              
                          <label className="midsize" key={input.slug+index} ><p>{input.nome} <span>{input.obrigatorio?"*":""}</span> </p>
                          <Country  
                              input={input}
                              AddDataField={AddDataField}
                              confirmInput={confirmInput}
                              countryData={countryData}
                              />
                          </label>
                      :input.tipo==="idnumerico"?
              
                          <label className="midsize" key={input.slug+index} ><p>{input.nome} <span>{input.obrigatorio?"*":""}</span> </p>
                          <NumericId  
                              input={input}
                              AddDataField={AddDataField}
                              confirmInput={confirmInput}
                              registerCustomData={registerCustomData}
                              />
                          </label>
                      :<></>
            ) 
        }):<></>  
    );
    
   
}       
        
