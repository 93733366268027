import styled from 'styled-components';

export const Container = styled.header`
    display:flex;
    justify-content: space-between;
    margin-bottom: 1em;
    align-items: flex-end;
    h1{
        font-size: 1.2rem;
        font-weight: 400;
        margin-bottom: .5rem;
    }
    p{
        font-weight: bold;
    }
    svg{
        margin-left: 1em;
    }
`