import styled from 'styled-components';

export const Container = styled.div`

    display:flex;
    justify-content: center;
    background-color: rgba(255,255,255,.3);
    position:fixed;
    width:100%;
    height:100%;
    top:0;
    svg {
        width:4em;
        animation: spin .7s ease-in-out infinite;
    }
    @keyframes spin {
        0% {
            transform: rotateZ(0);
        }
        100% {
            transform: rotateZ(360deg);
        }
    }
   
`