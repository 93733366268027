import React, {useState, useEffect} from 'react';
import {validation} from './services';

import './style.css';
export default function LoginSBDPE(props) {
    const [urlBase, setUrlBase] = useState("")
    const [neo, setNeo] = useState("")
    const [user, setUser] = useState("")
    const [pass, setPass] = useState("")
    
    useEffect(() => {
        const postHash = async () => {
            const validateHash = await validation(props.location.search.slice(6))
            if(validateHash.url)validationSucesso(validateHash.url)
            else{
                valiationErro(validateHash.message)
            }
        }
        postHash()
    }, [])

    const validationSucesso = async (url) => {
        success(url)
    }
    const valiationErro = (message) => {
        const mensagemFinal = document.querySelector('#mensagem_final');

        mensagemFinal.innerHTML = `<p>${message}</p> `
        
     }
     const success = (url) => {
        const mensagemFinal = document.querySelector('#mensagem_final');
        var tempo = 5;
        setInterval(() => {
            
            mensagemFinal.innerHTML = `<p>Você será redirecionado em ${tempo} segundos.</p> `
            if(tempo===1){

                redirect(url)
            }
            tempo -= 1
        }, 1000);

     }
     const redirect = async (urlRedirect) => {
        
        await setUrlBase(urlRedirect.split("?")[0])
        const params = urlRedirect.split("?")[1].split("&")
        await setNeo(params[0].split("=")[1])
        await setUser(params[1].split("=")[1])
        await setPass(params[2].split("=")[1])

        const formPost = document.querySelector('#testForm');

        formPost.submit()

    }
     
    return (
        <div className="container__default-sbdpe">
            <div id="mensagem_final"></div>
            <form id="testForm" method="post" action={urlBase} target="_top">
                <input type="hidden" name="neo" value={neo}/>
                <input type="hidden" name="user" value={user}/>
                <input type="hidden" name="password" value={pass}/>
            </form>
        </div>
    )

}
