import React from 'react';

export default function NumericId({
    input,
    AddDataField,
    confirmInput,
    registerCustomData
}) {
    function numberMask(value) {
      return value
          .replace(/\D/g, "") 
      }
   
    return (
        
        <input 
            name={input.nome} 
            type="text"
            placeholder={input.placeholder}
            onChange={e => AddDataField(input, numberMask(e.target.value))} 
            onBlur={e => input.obrigatorio?confirmInput(e.target):{}}
            required={input.obrigatorio?true:false}
        />
    );
    
   
}       
        
