import axios from "axios";

export async function validation(hash)  {
  const data = {
    "security-key":"483caa3c-45d9-46e9-89ef-7b8b8f87883a",
    "hash": hash
  }
  try {
      const config = {
          method: 'post',
           url: 'https://api.neoidea.live/v1/iscar/get-activation',
          //url: 'http://neoidea-api.mastercase.net/v1/iscar/get-activation',
          headers: { 
            'Content-Type': 'application/json', 
          },
          data : JSON.stringify(data)
        };

      const response = await axios(config)
      // console.log(response)
      return response.data;
  } catch (error) {
      return error.response.data;
      // console.log(error)
  }

}