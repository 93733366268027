import React , {useState} from 'react';
import * as utils from '../utils';
import {passwordCpf} from './services';


import './style.css';

export default function PasswordRecovery() {
    const [inputValue, setInputValue] = useState("")
    

    
    const handleSend = async (e) => {
        const button = document.querySelector('#button_send');
        e.preventDefault()
        
        button.setAttribute("disabled", "true")
        
        if(!utils.isCpf(inputValue)&&!utils.IsEmail(inputValue)) return noEmail()

        const data =  {
            cpf: inputValue
        }

        const resp = await passwordCpf(data)
  
        if(resp.email){
            return success(resp.email)
        }
        noSuccess(resp)
        button.removeAttribute("disabled")
    }
        
        
    const noEmail = () => {
        clearForm()
        const popUp = document.querySelector('#recovery_popup');
        const popUpText = document.querySelector('#recovery_popup_text');

        popUpText.innerHTML="Adicione um e-mail ou cpf válido.";
        popUp.classList.remove("hideDiv");

    }
    
    const success = (email) => {
        const form = document.querySelector('#retrive_password_form');
        var seconds = 5
        
        var interval = setInterval(() => {
            if (seconds ===1){
                window.clearInterval(interval)
                window.location.href = `${__dirname}loginSBDauth`; 
            }
            form.innerHTML=`<div><p>O link para recuperar sua senha foi enviado para o email: ${email}. </p><p>Você será redirecionado em ${seconds} segundos.</p></div>`;
            seconds -= 1
        }, 1000);
    }
    const noSuccess = (data) => {
        clearForm();
        
        const popUp = document.querySelector('#recovery_popup');
        const popUpText = document.querySelector('#recovery_popup_text');

    popUpText.innerHTML=`<p>${data}</p>`;
        popUp.classList.remove("hideDiv");
    } 
    const closePopUp = () => {
        const popUp = document.querySelector('#recovery_popup');
        popUp.classList.add("hideDiv");
        showForm()

    }
    const clearForm = () => {
        const input = document.querySelector('#input_cpf');
        const button = document.querySelector('#button_send');
        input.classList.add("hideDiv");
        button.classList.add("hideDiv");

    }
    const showForm = () => {
        const input = document.querySelector('#input_cpf');
        const button = document.querySelector('#button_send');
        input.classList.remove("hideDiv");
        button.classList.remove("hideDiv");

    }
  




    

    
    return (
        <div className="recovery">
            <form onSubmit={handleSend} id="retrive_password_form" className="recovery">
                <div className="recovery_popup hideDiv" id="recovery_popup"> 
                    <p id="recovery_popup_text"></p>
                    <button onClick={closePopUp} className="recovery recovery_popup_button" type="button">ok</button>
                </div>
                <input className="recovery"  id="input_cpf"  value={inputValue} onChange={e => setInputValue(e.target.value)} placeholder="Email ou CPF" />

                {/* <input className="recovery"  id="input_cpf" placeholder="CPF" /> */}
                <button className="recovery" id="button_send" type="submit">Enviar</button>
            </form>
        </div>
    )

}
