import React, {useState} from 'react';
import {dateMask, zipCodeMask, unsetWarning, setWarning, phoneMask, phoneDDIMask} from "../../../utils"
import {getAdress} from "../../api"
import 'react-phone-number-input/style.css'
import PhoneInput, { formatPhoneNumber, parsePhoneNumber } from 'react-phone-number-input'
import en from 'react-phone-number-input/locale/en.json'
import ptbr from 'react-phone-number-input/locale/pt-BR.json'

export default function SimpleForm({
    data,
    neoId,
    professionData,
    countryData,
    text,
    register,
    confirmInput,
    confirmInputPhone,
    confirmInputMobile,
    setPopupWarning,
    customFields,
    grupoId,
    layout
}) {

    const {registerData,setRegisterData} = data
    const {registerStatus} = register
    const [enableZipCode, setEnableZipCode] = useState(false)
    const [enableAdress, setEnableAdress] = useState(false)
    const showProfessionField = !customFields?.map(item=>item.tipo).includes("profissao")
    const showCountyField = !customFields?.map(item=>item.tipo).includes("pais")

    function confirmBirth(target) {
        const today = Date.now()
        const {value} = target
        const day = registerData.lang==="en"?value.slice(3, 5):value.slice(0, 2);
        const month = registerData.lang==="en"?value.slice(0, 2):value.slice(3, 5);
        const year = value.slice(-4);

        const time = new Date(month+"/"+day+"/"+year);
        
        if(!isNaN(time)&&(((today-time)/31104000000)>=8)){
            unsetWarning(target);
            setRegisterData({...registerData, birthday: year+"-"+month+"-"+day})
        }else {
            setWarning(target);
        }

    }
    function setcountrydata(value) {
        const county = countryData.find(country=> country.codigo===value)

        if(value){
            setEnableZipCode(true)
        }else{
            setEnableZipCode(false)
        }
        setRegisterData({...registerData, country:(value), zipCode:"", cellphoneDDI:"+"+county.ddi})
    }
    async function confirmAdress(target) {
        if(target.value){
            /*if(registerData.country==="21"){
                return setWarning(target);
            }*/

            setPopupWarning("loading", true)
            const adress = await getAdress(registerData)
            setPopupWarning("loading", false)

            if(adress){
                setRegisterData(registerData=>{
                    const newData = {...registerData}
                    if(adress.cidade) newData.city = adress.cidade
                    if(adress.estado) newData.state = adress.estado
                    if(adress.bairro) newData.region = adress.bairro
                    if(adress.logradouro) newData.adress = adress.logradouro
                    return newData
                })
                
            }
            setEnableAdress(true) 
            unsetWarning(target);

        }else {
            setWarning(target);
        }

    }
    
    const [value, setValue] = useState()
    return (
        registerStatus?<>

            <label className="midsize" htmlFor="date_of_birth"><p>{text.nascimeento} *</p>
                <input 
                    name={text.nascimeento} 
                    type="text" 
                    placeholder={text.dataFormat}
                    onChange={e => setRegisterData({...registerData, birthdayToShow:dateMask(e.target.value)})} 
                    onBlur={e=>confirmBirth(e.target)}
                    value={registerData.birthdayToShow}
                    required
                    />
            </label>
            {showProfessionField===false?<label className="midsize" htmlFor="profession"><p>{text.profissao}</p>
                <select 
                    name={text.profissao} 
                    onChange={e => setRegisterData({...registerData, profession:(e.target.value)})} 
                    value={registerData.profession}
                >
                    <option value="">{text.select}</option>
                    {professionData.map(profession=>{
                        return <option key={profession.codigo} value={profession.codigo}>{profession.valor}</option>
                    })}
                </select>
            </label>:<></>}
              <label className="midsize" htmlFor="Gender"><p>{text.genero} *</p>
                  <select 
                      name={text.genero} 
                      onChange={e => setRegisterData({...registerData, sex:(e.target.value)})} 
                      value={registerData.sex}
                      onBlur={e=>confirmInput(e.target)}
                      required
                  >
                      <option value="">{text.select}</option>
                      
                      <option value="Feminino">{text.feminino}</option>
                      <option value="Masculino">{text.masculino}</option>
                      <option value="Outro">{text.outro}</option>

                  </select>
              </label>
              <label className="midsize" htmlFor="cellphone"><p>{text.celular} *</p>
                  <PhoneInput
                      labels={layout.idioma == 'ptbr' ? ptbr : en}
                      defaultCountry={layout.idioma == 'ptbr' ? 'BR' : 'US'}
                      international={false}
                      name={text.celular} 
                      placeholder={text.celular} 
                      value={registerData.cellphone}
                      onChange={(value) => {
                          //console.log('Value: '+value)
                          let parsedPhone = null
                          if (value !== undefined&&value.toString()!='') {
                              parsedPhone = parsePhoneNumber(value.toString());
                          }
                          if (parsedPhone != null) {
                              value = `+${parsedPhone.countryCallingCode} ${formatPhoneNumber(value)}`
                              //console.log(value)
                          }
                          //console.log(value)
                          setRegisterData({...registerData, cellphone:value})
                      }}
                      onBlur={e=>confirmInputMobile(e.target,registerData.cellphone)}
                      required
                  />
              </label>
            <h1 className="userHeader">{text.userEnd}</h1>
              {showCountyField?<label className="midsize" htmlFor="country"><p>{text.pais} *</p>
                  <select 
                      name={text.pais} 
                      onChange={e => setcountrydata(e.target.value)} 
                      value={registerData.country}
                      onBlur={e=>confirmInput(e.target)}
                      required
                  >
                      <option value="">{text.select}</option>
                      {countryData.map(country=>{
                          return <option key={country.codigo} value={country.codigo}>{country.valor}</option>
                      })}
                  </select>
              </label>:<></>}
              <label className="midsize" htmlFor="Zip_code"><p>{text.cep} *</p>
                  <input 
                      name={text.cidade} 
                      placeholder={text.cep} 
                      type="text" 
                      disabled={!enableZipCode}
                      onChange={e => setRegisterData({...registerData, zipCode:registerData.country==="21"?zipCodeMask(e.target.value):e.target.value})} 
                      onBlur={e=>confirmAdress(e.target)}
                      value={registerData.zipCode}
                      required
                      />
              </label>
              <label className="midsize" htmlFor="state"><p>{text.estado} *</p>
                  <input 
                      name={text.estado} 
                      placeholder={text.estado} 
                      type="text" 
                      disabled={!enableAdress}
                      onChange={e => setRegisterData({...registerData, state:e.target.value})} 
                      onBlur={e=>confirmInput(e.target)}
                      value={registerData.state}
                      required
                      />
              </label>
              <label className="midsize" htmlFor="city"><p>{text.cidade} *</p>
                  <input 
                      name={text.cidade} 
                      placeholder={text.cidade} 
                      type="text" 
                      disabled={!enableAdress}
                      onChange={e => setRegisterData({...registerData, city:e.target.value})} 
                      onBlur={e=>confirmInput(e.target)}
                      value={registerData.city}
                      required
                      />
              </label>
              <label className="midsize" htmlFor="adress"><p>{text.endereco} *</p>
                  <input 
                      name={text.endereco} 
                      placeholder={text.endereco} 
                      type="text" 
                      disabled={!enableAdress}
                      onChange={e => setRegisterData({...registerData, adress:e.target.value})} 
                      onBlur={e=>confirmInput(e.target)}
                      value={registerData.adress||""}
                      required
                      />
              </label>
              <label className="midsize" htmlFor="region"><p>{text.bairro} *</p>
                  <input 
                      name={text.bairro} 
                      placeholder={text.bairro} 
                      type="text" 
                      disabled={!enableAdress}
                      onChange={e => setRegisterData({...registerData, region:e.target.value})} 
                      onBlur={e=>confirmInput(e.target)}
                      value={registerData.region}
                      required
                      />
              </label>
              <label className="midsize" htmlFor="number"><p>{text.numero} *</p>
                  <input 
                      name={text.numero} 
                      placeholder={text.numero} 
                      type="text" 
                      disabled={!enableAdress}
                      onChange={e => setRegisterData({...registerData, number:e.target.value})} 
                      onBlur={e=>confirmInput(e.target)}
                      value={registerData.number}
                      required
                      />
              </label>
              <label className="midsize" htmlFor="observations"><p>{text.complemento} </p>
                  <input 
                      name={text.complemento} 
                      placeholder={text.complemento} 
                      type="text" 
                      onChange={e => setRegisterData({...registerData, observations:e.target.value})} 
                      value={registerData.observations}
                      />
              </label>
            
        </>:<></>              
    );
   
}       

        
