import React from 'react';
import * as utils from '../utils';

import './style.css';

export default function PasswordRecovery() {
    

    
    const handleSend = async (e) => {
        const input = document.querySelector('#input_email');
        const button = document.querySelector('#button_send');

        e.preventDefault()
        const email = input.value.trim()

        if(utils.IsEmail(email)){
            button.setAttribute("disabled", "true")
            const url = `https://app.neoidea.com.br/sistema/index.php?r=outlet/services/recuperarSenhaFaExterno`
            const sendData = `&email=${email}`;
    
            await fetch(url+sendData, {method: 'POST'})
    
            .then(response => response.json())
            .then(data =>{ 
                if(data.retorno){
                    return success()
                }
                noSuccess(data.descricao)
                button.removeAttribute("disabled")
                }
            );
        }else{
            noEmail();
        }
        
    }
    const success = () => {
        const form = document.querySelector('#retrive_password_form');

        form.innerHTML="Verifique agora seu e-mail ou caixa de spam.";
    }
    const noEmail = () => {
        clearForm()
        const popUp = document.querySelector('#recovery_popup');
        const popUpText = document.querySelector('#recovery_popup_text');

        popUpText.innerHTML="<p>Adicione um e-mail válido.</p>";
        popUp.classList.remove("hideDiv");

    }
    const noSuccess = (data) => {
        clearForm();
        
        const popUp = document.querySelector('#recovery_popup');
        const popUpText = document.querySelector('#recovery_popup_text');

        popUpText.innerHTML=data;
        popUp.classList.remove("hideDiv");
    } 


    const closePopUp = () => {
        const popUp = document.querySelector('#recovery_popup');
        popUp.classList.add("hideDiv");
        showForm()

    }
    const clearForm = () => {
        const input = document.querySelector('#input_email');
        const button = document.querySelector('#button_send');
        input.classList.add("hideDiv");
        button.classList.add("hideDiv");

    }
    const showForm = () => {
        const input = document.querySelector('#input_email');
        const button = document.querySelector('#button_send');
        input.classList.remove("hideDiv");
        button.classList.remove("hideDiv");

    }
  




    

    
    return (
        <div className="recovery">
            <form onSubmit={handleSend} id="retrive_password_form" className="recovery">
                <div className="recovery_popup hideDiv" id="recovery_popup"> 
                    <p id="recovery_popup_text"></p>
                    <button onClick={closePopUp} className="recovery recovery_popup_button" type="button">ok</button>
                </div>
                <input className="recovery"  id="input_email" placeholder="Email" />
                <button className="recovery" id="button_send" type="submit">Enviar</button>
            </form>
        </div>
    )

}
