import React, { useState } from "react";
import * as utils from "../utils";
import { login } from "./services";

import "./style.css";

export default function PasswordRecovery() {
  const [urlBase, setUrlBase] = useState("");
  const [neo, setNeo] = useState("");
  const [user, setUser] = useState("");
  const [pass, setPass] = useState("");
  var interval;

  const handleSend = async (e) => {
    const inputEmail = document.querySelector("#input_email");
    const inputPassword = document.querySelector("#input_password");
    const button = document.querySelector("#button_send");

    e.preventDefault();
    const email = inputEmail.value.trim();
    const password = inputPassword.value;

    if (utils.IsEmail(email) && utils.IsSomething(password)) {
      button.setAttribute("disabled", "true");
      const auth = {
        email: email,
        password: password,
      };
      const data = await login(auth);

      if (data.url) {
        // caso resposta com url
        return success(data.url);
      }
      // caso erro
      noSuccess(data.message);
      button.removeAttribute("disabled");
    } else if (!utils.IsEmail(email)) {
      noEmail();
    } else {
      noPass();
    }
  };
  const success = async (url) => {
    setUrlBase(url.split("?")[0]);
    const params = url.split("?")[1].split("&");
    setNeo(params[0].split("=")[1]);
    setUser(params[1].split("=")[1]);
    setPass(params[2].split("=")[1]);

    const form = document.querySelector("#retrive_password_form");
    form.innerHTML = `<p>Sua ativação da inscrição no <strong>I Congresso Anual SBOT Online</strong> foi realizada com sucesso!<br>
        <a id="submitLink" >CLIQUE AQUI</a> Para conhecer a Plataforma do Evento.</p>`;

    const link = document.querySelector("#submitLink");
    link.addEventListener("click", redirectFunc);
  };
  const redirectFunc = async () => {
    const formPost = document.querySelector("#testForm");

    formPost.submit();
  };
  const noEmail = () => {
    clearForm();
    const popUp = document.querySelector("#input__defalt_popup");
    const popUpText = document.querySelector("#input__defalt_popup_text");

    popUpText.innerHTML = "<p>Adicione um e-mail válido.</p>";
    popUp.classList.remove("hideDiv");
  };
  const noPass = () => {
    clearForm();
    const popUp = document.querySelector("#input__defalt_popup");
    const popUpText = document.querySelector("#input__defalt_popup_text");

    popUpText.innerHTML = "<p>O campo senha deve ser preenchido.</p>";
    popUp.classList.remove("hideDiv");
  };
  const noSuccess = (data) => {
    clearForm();

    const popUp = document.querySelector("#input__defalt_popup");
    const popUpText = document.querySelector("#input__defalt_popup_text");

    popUpText.innerHTML = data;
    popUp.classList.remove("hideDiv");
  };
  const closePopUp = () => {
    const popUp = document.querySelector("#input__defalt_popup");
    popUp.classList.add("hideDiv");
    showForm();
  };
  const clearForm = () => {
    const inputEmail = document.querySelector("#input_email");
    const inputPassword = document.querySelector("#input_password");
    const button = document.querySelector("#button_send");
    inputEmail.classList.add("hideDiv");
    inputPassword.classList.add("hideDiv");
    button.classList.add("hideDiv");
  };
  const showForm = () => {
    const inputEmail = document.querySelector("#input_email");
    const inputPassword = document.querySelector("#input_password");
    const button = document.querySelector("#button_send");
    inputEmail.classList.remove("hideDiv");
    inputPassword.classList.remove("hideDiv");
    button.classList.remove("hideDiv");
  };

  return (
    <div className="input__defalt SbotFrom">
      <form
        onSubmit={handleSend}
        id="retrive_password_form"
        className="input__defalt"
      >
        <div className="input__defalt_popup hideDiv" id="input__defalt_popup">
          <p id="input__defalt_popup_text"></p>
          <button
            onClick={closePopUp}
            className="input__defalt input__defalt_popup_button"
            type="button"
          >
            ok
          </button>
        </div>
        <input className="input__defalt" id="input_email" placeholder="Email" />
        <input
          className="input__defalt"
          type="password"
          id="input_password"
          placeholder="Senha"
        />
        <button className="input__defalt" id="button_send" type="submit">
          Enviar
        </button>
      </form>
      <form id="testForm" method="post" action={urlBase} target="_top">
        <input type="hidden" name="neo" value={neo} />
        <input type="hidden" name="user" value={user} />
        <input type="hidden" name="password" value={pass} />
      </form>
    </div>
  );
}
