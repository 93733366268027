import React from 'react';

export default function TextArea({
    input,
    AddDataField,
    confirmInput,
    registerCustomData
}) {
   
    return (
        <textarea 
            rows="4"
            name={input.nome} 
            placeholder={input.placeholder}
            onChange={e => AddDataField(input, e.target.value)} 
            onBlur={e => input.obrigatorio?confirmInput(e.target):{}} 
            value={registerCustomData[input.slug]}
            required={input.obrigatorio?true:false}
        />
    );
    
   
}       
        
