import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './routes';
import { BrowserRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
import RouteChangeTracker from './pages/register/components/GoogleAnalytics';

import './global.css';

const TRACKING_ID = "UA-212551316-1";
ReactGA.initialize(TRACKING_ID);
ReactGA.pageview(window.location.pathname + window.location.search);


ReactDOM.render(
  <React.StrictMode>
    <Routes />
    <BrowserRouter>
      <RouteChangeTracker />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
